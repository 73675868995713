import React, { useState, useEffect, useRef, useMemo } from "react";

import {
  MapContainer,
  TileLayer,
  Marker,
  Polygon as Zone,
  LayersControl,
  useMapEvents,
  Popup,
  useMap,
} from "react-leaflet";
import "leaflet/dist/leaflet.css";
import L, { layerGroup, point } from "leaflet";

import SensorIcons from "../SVG-icons/SensorIcons";
import InhibitorIcons from "../SVG-icons/InhibitorIcons.js";
import Footer from "../Panels/Footer.js";
import SidePanel from "../Panels/SidePanel";
import ZonesServices from "../../services/ZonesServices.js";
import AlertMapUtils from "../utils/AlertMapUtils.js";
import { useAuth } from "../../providers/AuthContex.js";
import InhibitorServices from "../../services/InhibitorServices.js";
import SensorsServices from "../../services/SensorsServices.js";
import AlertServices from "../../services/AlertServices.js";
import { useMQTT } from "../../providers/MqttContex.js";
import HomeIcons from "../SVG-icons/HomeIcons.js";
import JoystickIcons from "../SVG-icons/JoystickIcons.js";
import DroneIcons from "../SVG-icons/DroneIcons.js";
import GlobalIcons from "../SVG-icons/GlobalIcons.js";
import InhibitorRadio from "./AlertMapMarkers/InhibitorRadio.js";
import { useTranslation } from "react-i18next";
import Header from "../Panels/Header.js";
import { message } from "antd";
import "../../components/Panels/css/ToolTip.css";
import ToolTip from "./ToolTip.js";

export default function AlertMap() {
  const [messageApi, contextHolder] = message.useMessage();
  const [sensorList, setSensorList] = useState([]);
  const [inhibitorsList, setInhibitorsList] = useState([]);
  const [zones, setZones] = useState(null);
  const [points, setPoints] = useState([]);
  const [radios, setRadios] = useState([]);
  const memoRadios = useMemo(() => {
    return radios;
  }, [radios]);
  const [alerts, setAlerts] = useState([]);
  const [selectedInhibitor, setSelectedInhibitor] = useState(null);
  const [selectedSensor, setSelectedSensor] = useState(null);
  const [alertsToIgnore, setAlertsToIgnore] = useState([]); // VER SI QUEDA O NO
  const [layers, setLayers] = useState({ sensors: true, zones: true });
  const [isProcessing, setIsProcessing] = useState(false);
  const [alertProcessing, setAlertProcessing] = useState(false);
  const [alarmFilter, setAlarmFilter] = useState({
    neutral: true,
    friendly: true,
    alert: true,
    warning: true,
  });
  const [selectedAlert, setSelectedAlert] = useState(null);
  const [center, setCenter] = useState(null);

  const { group } = useAuth();
  const { client, isConnected, subscribe, unsubscribe } = useMQTT();
  const { BaseLayer } = LayersControl;
  const { t, i18n } = useTranslation();

  const markerRefs = useRef([]);

  const defaultCenter = {
    lat: 41.37318481141028,
    lng: 2.1531474024100135,
    type: "home",
  };

  let loc_topic = `${group.realm}/${group.group_id}/Dedrone/Loc`;
  let rf_topic = `${group.realm}/${group.group_id}/Dedrone/Rf`;
  let alarm_info = `${group.realm}/${group.group_id}/Dedrone/AlarmInfo`;
  let inhibitors_topic = `${group.realm}/${group.group_id}/system/inhibitor`;
  let system_topic_sensors = `${group.realm}/${group.group_id}/system/sensors`;
  // let loc_topic = `Dedrone/${group.group_id}/Loc`;
  // let rf_topic = `Dedrone/${group.group_id}/Rf`;
  // let alarm_info = `Dedrone/${group.group_id}/AlarmInfo`;
  let end_topic = "/alert/end/topic/SQUADRONE";

  //SET DEL KEY ESC PARA DESELECCIONAR EL SENSOR/INHIBIDOR
  //SUBSCRIPCION Y DESUBSCRIPCION A TOPICS
  //GET DE LAS ZONAS, INHIBIDORES, SENSORES Y ALERTAS DEL REDIS

  const dangerScale = {
    "rgba(245,48,20,0.4)": 4,
    "rgba(115,164,245,0.4)": 3,
    "rgba(15,140,3,0.4)": 2,
    "no-zone": 1,
  };

  useEffect(() => {
    const handleEsc = (event) => {
      if (event.key === "Escape") {
        setSelectedSensor(null);
        setSelectedInhibitor(null);
        setSelectedAlert(null);
        markerRefs.current.forEach((popup) => {
          if (popup) {
            popup.closePopup();
          }
        });
      }
      setCenter(null);
    };

    getSensors();
    // getCurrentAlerts();
    getZones();

    window.addEventListener("keydown", handleEsc);
    return () => {
      window.removeEventListener("keydown", handleEsc);
    };
  }, []);

  useEffect(() => {
    console.log("ISCONNECTED: ", isConnected);
    // Subscribe to the topic
    if (isConnected) {
      subscribe(loc_topic);
      subscribe(rf_topic);
      subscribe(alarm_info);
      subscribe(end_topic);
      subscribe(inhibitors_topic);
      subscribe(system_topic_sensors);
      getInhibitors(client);

      client.on("disconnect", (err) => {
        setAlerts([]);
        setPoints([]);
      });

      client.on("error", (err) => {
        setAlerts([]);
        setPoints([]);
        //   client.end();
      });
    }

    return () => {
      unsubscribe(loc_topic);
      unsubscribe(rf_topic);
      unsubscribe(alarm_info);
      unsubscribe(end_topic);
      unsubscribe(inhibitors_topic);
      unsubscribe(system_topic_sensors);
    };
  }, [isConnected]);

  //INTERVAL PARA REVISION DE ALARMAS QUE NO ESTEN MAS DE 30 SEGUNDOS SIN UPDATE
  useEffect(() => {
    if (selectedAlert) {
      const index = points.findIndex(
        (point) => point.alertId === selectedAlert.alertId
      );
      const marker = markerRefs.current[index];
      if (marker) {
        marker.openPopup(); // Ensure the popup is opened when selectedAlert changes
      }
    }
    const interval = setInterval(() => {
      if (!isProcessing) {
        if (
          points.some((item) => new Date() - item.timestamp.getTime() >= 30000)
        ) {
          setIsProcessing(true);
          removePoints();
        }
      }
    }, 30000);
    return () => {
      clearInterval(interval);
    };
  }, [points]);

  //SETTEO DEL ON MESSAGE
  useEffect(() => {
    if (selectedAlert) {
      const index = points.findIndex(
        (point) => point.alertId === selectedAlert.alertId
      );
      const marker = markerRefs.current[index];
      if (marker) {
        marker.openPopup(); // Ensure the popup is opened when selectedAlert changes
      }
    }
    const messageHandler = (topic, message) => {
      console.log(message.toString());
      if (isProcessing) {
        console.warn(
          "MQTT message received but skipped due to ongoing processing."
        );
        return;
      }
      // console.log("MESSAGE RECEIVED: ", topic);

      // console.log(newMessage);
      if (topic == inhibitors_topic) {
        //handleInhibMessage(message);
      } else if (topic == system_topic_sensors) {
        getSensors();
      } else if (topic.includes("atl/inhibitor")) {
        const newMessage = JSON.parse(message.toString());

        if (newMessage.frequencies.length > 0 && newMessage.order_by != "") {
          messageApi.open({
            type: "success",
            content: `${t("Activación Inhibidor")} ${
              newMessage.inhibitor_name
            } por ${newMessage.order_by}`,
          });
        }

        if (newMessage.frequencies.length == 0 && newMessage.order_by != "") {
          messageApi.open({
            type: "success",
            content: `${t("Desactivación Inhibidor")} ${
              newMessage.inhibitor_name
            } por ${newMessage.order_by}`,
          });
        }

        setInhibitorsList((prevSectors) => {
          const sensorIndex = prevSectors.findIndex(
            (e) => e.id === newMessage.inhibitor
          );
          if (sensorIndex !== -1) {
            const updatedSectors = [...prevSectors];
            updatedSectors[sensorIndex] = {
              ...updatedSectors[sensorIndex],
              activeSectors:
                newMessage.frequencies.length === 0 ? [] : newMessage.sectors,
            };
            return updatedSectors;
          }
        });
      } else {
        const newMessage = JSON.parse(message.toString());
        if (newMessage.messageType === "sensor") {
          if (
            newMessage.data.info !== undefined &&
            newMessage.data.info.status === "end"
          ) {
            removePoints();
            removeAlerts();
            return;
          } else {
            onMessageSensor(newMessage);
            return;
          }
        } else {
          normalizeRadio(newMessage.data);
          return;
        }
      }
    };

    // Attach the message handler
    client.on("message", messageHandler);

    // Clean up the listener when component unmounts or dependencies change
    return () => {
      client.off("message", messageHandler);
    };
  }, [points, alerts, zones]);

  useEffect(() => {
    const interval = setInterval(() => {
      removeAlerts();
    }, 5000);
    // updatePopupContent(selectedAlert);
    return () => clearInterval(interval);
  }, [alerts]);

  useEffect(() => {
    if (selectedAlert) {
      // const index = points.findIndex(
      //   (point) => point.alertId === selectedAlert.alertId
      // );
      // const marker = markerRefs.current[index];
      // if (marker) {
      //   marker.openPopup(); // Ensure the popup is opened when selectedAlert changes
      // }
    }
  }, [selectedAlert]);

  const sendInhibitorMessage = async (message) => {
    try {
      if (message.action == "start") {
        const response = await InhibitorServices.putActivateInhib(
          message,
          message.inhibitor
        );
        console.log("response: ", response);
      } else {
        const response = await InhibitorServices.putDeactivateInhib(
          message,
          message.inhibitor
        );
        console.log("response: ", response);
      }
    } catch (error) {
      console.log("sendInhibitorMessage error: ", error);
    }
    // if (client && client.connected) {
    //   client.publish(inhibitors_topic, JSON.stringify(message), (err) => {
    //     if (err) {
    //       console.error("Failed to send message: ", err);
    //     } else {
    //       console.log(`Message sent to ${inhibitors_topic}: ${message}`);
    //     }
    //   });
    // } else {
    //   console.error("Client is not connected");
    // }
  };

  const handleInhibMessage = (message) => {
    const uint8Array = new Uint8Array(message);

    const stringMessage = new TextDecoder().decode(uint8Array);
    let decodedMessage = JSON.parse(stringMessage);
    console.log(decodedMessage);
    if (decodedMessage.action === "start") {
      setInhibitorsList((prevSectors) => {
        const sensorIndex = prevSectors.findIndex(
          (e) => e.id === decodedMessage.inhibitor
        );
        if (sensorIndex !== -1) {
          const updatedSectors = [...prevSectors];
          updatedSectors[sensorIndex] = {
            ...updatedSectors[sensorIndex],
            activeSectors: JSON.parse(decodedMessage.sectors),
          };
          return updatedSectors;
        }
      });
      messageApi.open({
        type: "success",
        content: `${t("Activación Inhibidor")} ${
          decodedMessage.inhibitor_name
        }`,
      });
    } else {
      setInhibitorsList((prevSectors) => {
        const sensorIndex = prevSectors.findIndex(
          (e) => e.id === decodedMessage.inhibitor
        );
        if (sensorIndex !== -1) {
          const updatedSectors = [...prevSectors];
          updatedSectors[sensorIndex] = {
            ...updatedSectors[sensorIndex],
            activeSectors: [],
          };
          return updatedSectors;
        }
      });
      messageApi.open({
        type: "success",
        content: `${t("Desactivación Inhibidor")} ${
          decodedMessage.inhibitor_name
        }`,
      });
    }
  };

  function getDestinationLatLon(lat, lng, distance, bearing) {
    //NEW
    const R = 6371; // Earth radius in kilometers
    const rad = Math.PI / 180;

    const lat1 = lat * rad;
    const lon1 = lng * rad;

    const lat2 = Math.asin(
      Math.sin(lat1) * Math.cos(distance / R) +
        Math.cos(lat1) * Math.sin(distance / R) * Math.cos(bearing * rad)
    );

    const lon2 =
      lon1 +
      Math.atan2(
        Math.sin(bearing * rad) * Math.sin(distance / R) * Math.cos(lat1),
        Math.cos(distance / R) - Math.sin(lat1) * Math.sin(lat2)
      );

    return { lat: lat2 / rad, lng: lon2 / rad };
  }

  function createIsoscelesTriangle(A, B, baseLength, direction_error, range) {
    //NEW
    const { lat: latA, lng: lonA } = A; // -> sensor
    const { lat: latB, lng: lonB } = B; // -> rango del sensor

    // Calculate the bearing from A to B
    const rad = Math.PI / 180;
    const dLon = (lonB - lonA) * rad;
    const y = Math.sin(dLon) * Math.cos(latB * rad);
    const x =
      Math.cos(latA * rad) * Math.sin(latB * rad) -
      Math.sin(latA * rad) * Math.cos(latB * rad) * Math.cos(dLon);
    const bearingAB = (Math.atan2(y, x) / rad + 360) % 360;

    // Calculate the bearings to the two base points (P1 and P2)
    const baseBearing1 = (bearingAB + 90) % 360; // Perpendicular to A-B
    const baseBearing2 = (bearingAB - 90 + 360) % 360;

    // Calculate the coordinates of the two base points (P1 and P2)
    let halfBaseLength = baseLength / 2;

    //remove if crash
    if (direction_error !== undefined && direction_error != null) {
      halfBaseLength =
        Math.abs(range * Math.tan(direction_error * (Math.PI / 180))) / 2;
    }
    const P1 = getDestinationLatLon(latB, lonB, halfBaseLength, baseBearing1);
    const P2 = getDestinationLatLon(latB, lonB, halfBaseLength, baseBearing2);

    return [P1, P2, A];
  }

  //HANDLER PARA EL MENSAJE QUE VIENE POR EL MQTT
  const onMessageSensor = async (message) => {
    if (isProcessing) {
      console.warn("Message skipped due to ongoing processing.");
      return;
    }

    setIsProcessing(true);
    try {
      for (let index = 0; index < message.data.length; index++) {
        let data = message.data[index];
        if (alertsToIgnore.includes(data.id)) {
          continue;
        }
        let radioS = [];

        let bearing = data.bearing;
        let direction_error = data.direction_error; //remove if error
        if (bearing != undefined && bearing != null) {
          let center = { lat: Number(data["lat"]), lng: Number(data["lng"]) };
          let sensorRange = sensorList.find(
            (element) => element.id === data.id
          );

          let obj = AlertMapUtils.bearingDistance(
            data["lat"],
            data["lng"],
            sensorRange.range / 1000, //KM
            bearing
          );
          let newPoint = {
            lat: Number(obj["latitude"]),
            lng: Number(obj["longitude"]),
          };

          // let triangle = createIsoscelesTriangle(
          //   { lat: data["lat"], lng: data["lng"] },
          //   newPoint,
          //   1,
          // );

          let triangle = createIsoscelesTriangle(
            { lat: data["lat"], lng: data["lng"] },
            newPoint,
            1,
            direction_error,
            sensorRange.range / 1000
          );

          // console.log("TRIANGULO: ", triangle); //NEW

          // console.log("PATH2: ", [center, newPoint2, newPoint3, newPoint]);
          let colorHaz =
            data.type_of_detection == "drone"
              ? "rgba(115,164,245,0.7)"
              : "rgba(245,48,20,0.7)";
          let colorBorde =
            data.type_of_detection == "drone" ? "#03378C" : "#F53014";
          radioS.push({
            alertId: data.alertId,
            path: [
              [triangle[0].lat, triangle[0].lng],
              [triangle[1].lat, triangle[1].lng],
              [triangle[2].lat, triangle[2].lng],
            ],
            fillColor: colorHaz,
            strokeColor: colorBorde,
            stroke: 0,
          });
          // setRadios((prevRadios) => [...prevRadios, ...radioS]);
          // setRadios((prevRadios) =>
          //   prevRadios.map(
          //     (radio) =>
          //       radio.alertId === radioS[0].alertId
          //         ? { ...radio, ...radioS[0] } // Update the matching radio
          //         : radio // Keep other radios unchanged
          //   )
          // );
        }
        // console.log("RADIOS: ", [...radios, ...radioS]);

        setSensorList((prevSensors) => {
          const sensorIndex = prevSensors.findIndex((e) => e.id === data.id);
          if (sensorIndex !== -1) {
            const updatedSensors = [...prevSensors];
            updatedSensors[sensorIndex] = {
              ...updatedSensors[sensorIndex],
              bearing:
                data.bearing === null
                  ? prevSensors[sensorIndex].bearing
                  : data.bearing,
              direction_error:
                data.direction_error === null
                  ? prevSensors[sensorIndex].direction_error
                  : data.direction_error,
              lat: data.lat,
              lng: data.lng,
              timestamp: new Date(),
              radios:
                radioS.length > 0 ? radioS : updatedSensors[sensorIndex].radios,
              radiosFailCount:
                radioS.length > 0
                  ? 0
                  : (updatedSensors[sensorIndex].radiosFailCount += 1),
            };
            return updatedSensors;
          } else {
            return [
              ...prevSensors,
              {
                id: data.id,
                lat: data.lat,
                lng: data.lng,
                timestamp: new Date(),
                radios: radioS,
                radiosFailCount: 0,
              },
            ];
          }
        });
      }
    } catch (error) {
      console.error("Error processing sensor message:", error);
    } finally {
      setIsProcessing(false);
    }
  };

  //REMOVE DE PUNTOS
  const removePoints = async () => {
    try {
      if (isProcessing) {
      }
      let pointsArray = points.filter(
        (item) => new Date() - item.timestamp.getTime() <= 30000
      );
      console.log("POINTSARRAY AFTER REMOVE: ", pointsArray);
      setPoints(pointsArray);
    } catch (error) {
      console.error("Error removing points:", error);
    } finally {
      setIsProcessing(false);
    }
  };

  //REMOVE DE ALERTAS
  const removeAlerts = async () => {
    try {
      if (isProcessing) {
      }
      let alertsArray = alerts.filter(
        (al) => new Date() - al.timestamp <= 30000
      );
      setAlerts(alertsArray);
    } catch (error) {
      console.error("Error removing alerts:", error);
    } finally {
      setIsProcessing(false);
    }
  };

  //GET DE LAS ZONAS PARA PINTAR EN EL MAPA
  const getZones = async () => {
    try {
      const response = await ZonesServices.getZonesByGroup();
      setZones(AlertMapUtils.normalizeZones(response.data));
    } catch (error) {
      console.log("getZones error: ", error);
    }
  };

  //GET GEL LISTADO DE SENSORES POR GRUPO
  const getSensors = async (process) => {
    try {
      const res = await SensorsServices.getSensors();
      setSensorList(AlertMapUtils.normalizeSensors(res.data));
    } catch (error) {
      console.log("getSensors error: ", error);
    }
  };

  //GET DEL LISTADO DE INHIBIDORES POR GRUPO
  const getInhibitors = async (client) => {
    try {
      const response = await InhibitorServices.getInhibitors();
      response.data.forEach((element) => {
        const topic = `atl/inhibitor/${element.inhibitorid}/response`;
        client.subscribe(topic, (err) => {
          if (!err) {
            console.log(
              "Subscribed to: ",
              `atl/inhibitor/${element.inhibitorid}/response`
            );
          } else {
            console.error("Subscription error:", topic);
          }
        });
      });
      setInhibitorsList(AlertMapUtils.normalizeInhibitors(response.data));
    } catch (error) {
      console.log("getInhibitors error: ", error);
    }
  };

  //GET DE LAS ALERTAS ACTUALES DE REDIS
  const getCurrentAlerts = async () => {
    try {
      const res = await AlertServices.getCurrentAlerts();
      const redisAlerts = [];
      res.data.forEach((element) => {
        redisAlerts.push({
          alertId: element.data.info.alertId,
          lat: element.data.point.lat,
          lng: element.data.point.lng,
          type: element.data.point.type,
          timestamp: new Date(),
        });
      });
      setPoints(redisAlerts);
    } catch (error) {
      console.log("getCurrentAlerts error: ", error);
    }
  };

  //NORAMLIZAR PUNTOS
  const normalizeRadio = (data) => {
    // console.log("DATA: ", data);
    let alertId = data.info.alertId;
    let alertObj = makeAlert(data);
    // let changeS = sensors;
    // let sensor = null;
    for (let element in data) {
      if (element == "info" || element == "timestamp") {
        continue;
      }
      if (element != "point") {
        // let sensorl = sensors.filter((e) => e.id == element);
        // let add = false;
        // if (sensorl.length > 0) {
        //   sensor = sensorl[0];
        // } else {
        //   add = true;
        //   sensor = { id: element };
        // }
        // sensor["lat"] = data[element]["lat"];
        // sensor["lng"] = data[element]["lng"];
        // sensor["alertId"] = alertId;
        // if (add) {
        //   changeS.push(sensor);
        // }
      } else {
        let pointZone = findZone(
          { lat: data[element]["lat"], lng: data[element]["lng"] },
          zones != null ? zones : []
        );

        pointZone = pointZone != null ? pointZone : "no-zone";

        let pointsS = [...points];
        let type =
          data[element]["type"] == undefined ? "drone" : data[element]["type"];
        let drone = pointsS.filter(
          (e) => e.alertId == alertId && e.type == type
        );

        if (drone.length > 0) {
          drone = drone[0];

          drone["lat"] = data[element]["lat"];
          drone["lng"] = data[element]["lng"];
          drone["timestamp"] = new Date();
          drone["speed"] = data[element]["speed"];
          drone["altitude"] = data[element]["altitude"];
          drone["heading"] = data[element]["heading"];
          drone["alarmType"] =
            dangerScale[pointZone.toString()] > drone["alarmType"]
              ? dangerScale[pointZone.toString()]
              : drone["alarmType"];
        } else {
          drone = {
            alertId: alertId,
            lat: data[element]["lat"],
            lng: data[element]["lng"],
            speed: data[element]["speed"],
            altitude: data[element]["altitude"],
            heading: data[element]["heading"],
            type: type,
            timestamp: new Date(),
            alarmType: dangerScale[pointZone],
          };
          pointsS.push(drone);
        }

        setPoints(pointsS);
      }
    }

    // Ajust points
    const sensorsLocal = sensorList.filter((item) => item.alertId == alertId);
    // alertObj["sensors"] = sensorsLocal;

    const pointsLocal = points.filter((item) => item.alertId == alertId);
    // alertObj["points"] = pointsLocal;
    //setAlertActive(alerts);
  };

  //CREAR ALERTA
  const makeAlert = (data) => {
    if (alertProcessing) {
      console.log("RETURN");
      return;
    }
    setAlertProcessing(true);
    try {
      const alertsLocal = alerts.filter(
        (item) => item.info.alertId === data.info.alertId
      );
      const alertSensors = Object.keys(data).filter(
        (key) => key !== "point" && key !== "info"
      );

      if (alertsLocal.length === 0) {
        let alertObj = {
          alertId: data.info.alertId,
          timestamp: Date.now(),
          info: data.info,
          points: data.point,
          manufacturer:
            data.drone_info.manufacturer != undefined
              ? data.drone_info.manufacturer
              : "",
          model:
            data.drone_info.model != undefined ? data.drone_info.model : "",
          startTime: new Date(),
          type_alarm:
            data.drone_info.type != undefined ? data.drone_info.type : "",
          sensors_id: [...alertSensors],
          serialNumber: data.drone_info.serialNumber,
        };

        const alertsL = [...alerts, alertObj];
        setAlerts(alertsL);

        return alertObj;
      } else {
        const existingAlert = { ...alertsLocal[0] };
        existingAlert.info = { ...existingAlert.info, ...data.info };
        existingAlert.sensors_id = [...alertSensors];
        existingAlert.timestamp = new Date();
        existingAlert.points = data.point;
        existingAlert.manufacturer = data.drone_info.manufacturer;
        existingAlert.model = data.drone_info.model;
        existingAlert.type = data.drone_info.type;

        const updatedAlerts = [...alerts].map((alert) => {
          if (alert.alertId === existingAlert.alertId) {
            return existingAlert;
          } else {
            return alert;
          }
        });

        setAlerts(updatedAlerts);

        return existingAlert;
      }
    } catch (error) {
      console.log("makeAlert error: ", error);
    } finally {
      setAlertProcessing(false);
    }
  };

  const onSelectSensor = (sensor) => {
    setSelectedInhibitor(null);
    setSelectedAlert(null);
    setSelectedSensor(sensor);
    if (sensor != null) {
      setCenter([sensor.lat, sensor.lng]);
    }
  };

  const onSelectInhibitor = (inhib) => {
    setSelectedSensor(null);
    setSelectedAlert(null);
    setSelectedInhibitor(inhib);
    if (inhib != null) {
      setCenter([inhib.lat, inhib.lng]);
    }
  };

  const onSelectAlert = (alert, index) => {
    console.log("ALERT: ", alert);
    setSelectedAlert(alert);
    setSelectedSensor(null);
    setSelectedInhibitor(null);
    markerRefs.current.forEach((popup) => {
      if (popup) {
        popup.closePopup();
      }
    });
    setCenter(null);
    let alertItem = null;
    if (alert) {
      alertItem = alerts.find((item) => item.alertId === alert.alertId);
    }
    //     if (alertItem) {
    //       console.log("alertitem: ", alertItem);
    //       const popupContent = `
    //         <div style="text-align: center;" class="custom-popup">
    //           <div>
    //             <svg width="19" height="11" viewBox="0 0 19 11" fill="none" xmlns="http://www.w3.org/2000/svg">
    //               <mask id="mask0_53_371" style="mask-type:luminance" maskUnits="userSpaceOnUse" x="0" y="0" width="19" height="11">
    //               <path d="M18.1471 0.799805H0.914062V10.0148H18.1471V0.799805Z" fill="white"/>
    //               </mask>
    //               <g mask="url(#mask0_53_371)">
    //               <path d="M0.935051 9.95475C0.839547 8.45153 1.14006 6.94956 1.80659 5.59881C2.47313 4.24807 3.48235 3.09581 4.73348 2.25709C5.98462 1.41838 7.43389 0.922562 8.93659 0.819148C10.4393 0.715733 11.9428 1.00834 13.2971 1.66775L13.2291 1.71875C12.4351 2.29609 11.6404 2.87275 10.8451 3.44875C10.8334 3.45608 10.8204 3.46092 10.8068 3.46299C10.7932 3.46505 10.7793 3.46429 10.7661 3.46075C10.1766 3.26585 9.56528 3.1446 8.94605 3.09975C7.56019 2.99717 6.17314 3.28252 4.94032 3.92384C3.70749 4.56515 2.67761 5.53709 1.96605 6.73075C1.57614 7.38011 1.28876 8.08575 1.11405 8.82275C1.03179 9.18044 0.973655 9.54326 0.940051 9.90875V9.95475" fill="white"/>
    //               <path d="M13.2561 4.86593L14.8671 2.64893C14.9671 2.72793 15.0571 2.80393 15.1491 2.88193C16.7661 4.26926 17.8106 6.20745 18.0801 8.32093C18.1289 8.69321 18.1513 9.06848 18.1471 9.44393C18.1431 9.58809 18.0857 9.72564 17.9862 9.82999C17.8866 9.93435 17.7519 9.9981 17.6081 10.0089C17.4081 10.0189 17.2171 10.0089 17.0211 10.0089C16.8251 10.0089 16.6101 10.0089 16.4041 10.0089C16.2545 10.0148 16.1083 9.96349 15.9952 9.86541C15.882 9.76733 15.8105 9.62985 15.7951 9.48093C15.5398 7.70686 14.6545 6.08394 13.3011 4.90893L13.2651 4.87693C13.2615 4.87162 13.2585 4.86592 13.2561 4.85993" fill="white"/>
    //               <path d="M14.6889 1.61061L14.4239 1.97661C13.9126 2.67661 13.4009 3.37661 12.8889 4.07661L11.1109 6.52061L9.76094 8.38661C9.54194 8.68661 9.32594 8.99361 9.10094 9.28661C8.98818 9.42274 8.84405 9.52946 8.68094 9.59761C8.36553 9.75101 8.00617 9.78839 7.66594 9.70319C7.32572 9.61799 7.02638 9.41566 6.8205 9.13171C6.61461 8.84777 6.51533 8.50037 6.5401 8.15052C6.56487 7.80066 6.7121 7.47071 6.95594 7.21861C7.07324 7.11626 7.19791 7.02267 7.32894 6.93861L9.42894 5.41461L11.6079 3.83061L14.6189 1.64161L14.6809 1.59961L14.6879 1.60661" fill="white"/>
    //               </g>
    //             </svg>
    //  ${alertItem.points ? Math.ceil(alertItem.points.speed) : "0"} m/s</div>
    //           <div><svg width="8" height="15" viewBox="0 0 8 15" fill="none" xmlns="http://www.w3.org/2000/svg">
    // <mask id="mask0_53_379" style="mask-type:luminance" maskUnits="userSpaceOnUse" x="0" y="0" width="8" height="15">
    // <path d="M7.63407 0.0600586H0.195068V14.9411H7.63407V0.0600586Z" fill="white"/>
    // </mask>
    // <g mask="url(#mask0_53_379)">
    // <path d="M7.09498 10.7593C6.25498 10.7653 5.41498 10.7593 4.57498 10.7593H4.38898V4.1783H4.58098C5.42098 4.1783 6.26098 4.1783 7.10098 4.1783C7.20388 4.1882 7.30727 4.16504 7.39611 4.11217C7.48494 4.0593 7.55461 3.97947 7.59498 3.8843C7.63629 3.79 7.64535 3.6847 7.62077 3.58473C7.59618 3.48476 7.53931 3.39568 7.45898 3.3313C6.42398 2.3013 5.38898 1.2733 4.36098 0.238296C4.31216 0.183422 4.25248 0.139291 4.18571 0.108703C4.11894 0.0781153 4.04655 0.0617377 3.97311 0.0606094C3.89968 0.0594811 3.82681 0.0736268 3.75914 0.102149C3.69146 0.130671 3.63045 0.172948 3.57998 0.226296C2.51998 1.25963 1.45531 2.28863 0.385976 3.3133C0.302442 3.37752 0.242253 3.46734 0.214621 3.56902C0.186989 3.6707 0.193436 3.77863 0.232976 3.8763C0.274167 3.97641 0.34729 4.06011 0.440972 4.11436C0.534653 4.16862 0.643639 4.19039 0.750975 4.1763C1.57998 4.1763 2.40898 4.1763 3.23698 4.1763H3.41098V10.7593H3.22098C2.39198 10.7593 1.56298 10.7593 0.733975 10.7593C0.646719 10.7548 0.559428 10.7674 0.476976 10.7963C0.402918 10.8277 0.338173 10.8777 0.28894 10.9413C0.239707 11.0049 0.207634 11.0802 0.195792 11.1597C0.183951 11.2393 0.192736 11.3206 0.221309 11.3958C0.249881 11.471 0.297283 11.5377 0.358976 11.5893C0.936309 12.1693 1.51498 12.748 2.09498 13.3253C2.58498 13.8123 3.07298 14.2993 3.56898 14.7803C3.61271 14.8325 3.66768 14.8741 3.72978 14.9021C3.79187 14.9301 3.85949 14.9437 3.92757 14.9418C3.99565 14.94 4.06243 14.9228 4.12294 14.8916C4.18344 14.8603 4.2361 14.8158 4.27698 14.7613C5.33231 13.7146 6.38698 12.6673 7.44098 11.6193C7.52387 11.5537 7.58342 11.4631 7.6108 11.3609C7.63818 11.2588 7.63193 11.1506 7.59298 11.0523C7.55543 10.9534 7.48568 10.87 7.39498 10.8156C7.30428 10.7612 7.1979 10.7389 7.09298 10.7523" fill="white"/>
    // </g>
    // </svg>
    //  ${alertItem.points ? Math.ceil(alertItem.points.altitude) : "0"} m</div>
    //         </div>`;
    //       if (
    //         markerRefs.current.length > 0 &&
    //         markerRefs.current[index] != null &&
    //         markerRefs.current[index] != undefined
    //       ) {
    //         markerRefs.current[index]
    //           .bindPopup(popupContent, { offset: [0, -30] })
    //           .openPopup();
    //       }
    //     }
  };

  //FUNCION PARA SELECCIONAR ALARMAS QUE NO QUERES TRACKEAR
  const handleAlertTracking = (id) => {
    return;
    // if(alertsToIgnore.includes(id)){
    //   let alertsList = [...alertsToIgnore]
    //   alertsList = alertsList.filter(item => item !== id);
    //   setAlertsToIgnore(alertsList)
    // } else {
    //   let alertsList = [...alertsToIgnore]
    //   alertsList.push(id)
    //   setAlertsToIgnore(alertsList)
    // }
  };

  //FUNCION QUE RETORNA EL ICONO CORRESPONDIENTE DEL PUNTO (HOME, PILOTO O REMOTE)
  const isPointInsideZone = (point, polygon) => {
    const { lat: x, lng: y } = point;
    const path = polygon.path;
    let inside = false;

    for (let i = 0, j = path.length - 1; i < path.length; j = i++) {
      const xi = path[i].lat,
        yi = path[i].lng;
      const xj = path[j].lat,
        yj = path[j].lng;

      const intersect =
        yi > y !== yj > y && x < ((xj - xi) * (y - yi)) / (yj - yi) + xi;
      if (intersect) inside = !inside;
    }

    return inside;
  };
  const findZone = (point, polygons) => {
    for (const polygon of polygons) {
      if (isPointInsideZone(point, polygon)) {
        return polygon.fillColor; // Return the polygon ID that contains the point
      }
    }
    return null;
  };
  const droneIcon = (element) => {
    const pointZone = element.alarmType;
    if (element.type === "home") {
      if (pointZone == 4) {
        return {
          icon: {
            iconUrl: alarmFilter.alert
              ? HomeIcons.alertHome.url
              : GlobalIcons.transparent.url,
            iconSize: [25, 25],
            iconAnchor: [12, 20],
          },
          layer: 10000,
        };
      }
      if (pointZone == 3) {
        return {
          icon: {
            iconUrl: alarmFilter.warning
              ? HomeIcons.warningHome.url
              : GlobalIcons.transparent.url,
            iconSize: [25, 25],
            iconAnchor: [12, 20],
          },
          layer: 10000,
        };
      }

      if (pointZone == 2) {
        return {
          icon: {
            iconUrl: alarmFilter.friendly
              ? HomeIcons.friendlyHome.url
              : GlobalIcons.transparent.url,
            iconSize: [25, 25],
            iconAnchor: [12, 20],
          },
          layer: 10000,
        };
      }
      return {
        icon: {
          iconUrl: alarmFilter.neutral
            ? HomeIcons.neutralHome.url
            : GlobalIcons.transparent.url,
          iconSize: [25, 25],
          iconAnchor: [12, 20],
        },
        layer: 10000,
      };
    } else if (element.type === "remote") {
      if (pointZone == 4) {
        return {
          icon: {
            iconUrl: alarmFilter.alert
              ? JoystickIcons.alertJoystick.url
              : GlobalIcons.transparent.url,
            iconSize: [25, 25],
            iconAnchor: [12, 20],
          },
          layer: 100000,
        };
      }
      if (pointZone == 3) {
        return {
          icon: {
            iconUrl: alarmFilter.warning
              ? JoystickIcons.warningJoystick.url
              : GlobalIcons.transparent.url,
            iconSize: [25, 25],
            iconAnchor: [12, 20],
          },
          layer: 100000,
        };
      }
      if (pointZone == 2) {
        return {
          icon: {
            iconUrl: alarmFilter.friendly
              ? JoystickIcons.friendlyJoystick.url
              : GlobalIcons.transparent.url,
            iconSize: [25, 25],
            iconAnchor: [12, 20],
          },
          layer: 100000,
        };
      }
      return {
        icon: {
          iconUrl: alarmFilter.neutral
            ? JoystickIcons.neutralJoystick.url
            : GlobalIcons.transparent.url,
          iconSize: [25, 25],
          iconAnchor: [12, 20],
        },
        layer: 100000,
      };
    }

    if (pointZone == 4) {
      return {
        icon: {
          iconUrl: alarmFilter.alert
            ? DroneIcons.alertDrone.url
            : GlobalIcons.transparent.url,
          iconSize: [25, 25],
          iconAnchor: [12, 20],
        },
        layer: 1000000,
      };
    }
    if (pointZone == 3) {
      return {
        icon: {
          iconUrl: alarmFilter.warning
            ? DroneIcons.warningDrone.url
            : GlobalIcons.transparent.url,
          iconSize: [25, 25],
          iconAnchor: [12, 20],
        },
        layer: 1000000,
      };
    }
    if (pointZone == 2) {
      return {
        icon: {
          iconUrl: alarmFilter.friendly
            ? DroneIcons.friendlyDrone.url
            : GlobalIcons.transparent.url,
          iconSize: [25, 25],
          iconAnchor: [12, 20],
        },
        layer: 1000000,
      };
    }
    return {
      icon: {
        iconUrl: alarmFilter.neutral
          ? DroneIcons.neutralDrone.url
          : GlobalIcons.transparent.url,
        iconSize: [25, 25],
        iconAnchor: [12, 20],
      },
      layer: 1000000,
    };
  };
  const sensorIcon = (element) => {
    if (
      alerts.some(
        (alert) =>
          alert.sensors_id.includes(element.id) &&
          new Date() - alert.timestamp <= 1000
      )
    ) {
      return {
        icon: {
          iconUrl: SensorIcons.alertSensorIcon.url,
          iconSize: [28, 28],
          iconAnchor: [12, 20],
        },
        layer: 100,
      };
    }
    if (selectedSensor !== null && selectedSensor.id === element.id) {
      return {
        icon: {
          iconUrl: SensorIcons.selectedSensorIcon.url,
          iconSize: [28, 28],
          iconAnchor: [12, 20],
        },
        layer: 1000000,
      };
    } else {
      return {
        icon: {
          iconUrl: SensorIcons.defaultSensorIcon.url,
          iconSize: [28, 28],
          iconAnchor: [12, 20],
        },
        layer: 1,
      };
    }
  };
  const inhibitorIcon = (element) => {
    if (selectedInhibitor !== null && selectedInhibitor.id === element.id) {
      return {
        icon: {
          iconUrl: InhibitorIcons.selectedInhibIcon.url,
          iconSize: [28, 28],
          iconAnchor: [12, 20],
        },
        layer: 100,
      };
    } else {
      return {
        icon: {
          iconUrl: InhibitorIcons.defaultInhibIcon.url,
          iconSize: [28, 28],
          iconAnchor: [12, 20],
        },
        layer: 1,
      };
    }
  };
  const MapClickHandler = () => {
    useMapEvents({
      click: (e) => {
        setSelectedSensor(null);
        setSelectedInhibitor(null);
        setSelectedAlert(null);
        markerRefs.current.forEach((popup) => {
          if (popup) {
            popup.closePopup();
          }
        });
        setCenter(null);
      },
    });

    return null;
  };

  const PopUpMarker = ({ alert, alertItem }) => {
    if (!alert || !alertItem) return null;

    return (
      <Popup>
        <div style={{ textAlign: "center" }}>
          <div>
            {t("Velocidad")}:{" "}
            {alertItem.points ? Math.ceil(alertItem.points.speed) : "0"} m/s
          </div>
          <div>
            {t("Altura")}:{" "}
            {alertItem.points ? Math.ceil(alertItem.points.altitude) : "0"} m
          </div>
        </div>
      </Popup>
    );
  };

  const CenterMapOnMarker = ({ position }) => {
    const map = useMap();
    if (position) {
      map.setView(position, map.getZoom());
    }
    return null;
  };

  // const updatePopupContent = (alert) => {
  //   if (!alert) return;

  //   const alertItem = alerts.find((item) => item.alertId === alert.alertId);
  //   const index = alerts.findIndex((obj) => obj.alertId === alert.alertId);
  //   if (
  //     alertItem &&
  //     markerRefs.current[index] &&
  //     (alertItem.points?.speed != alert.point?.speed ||
  //       alertItem.points?.altitude != alert.point?.altitude)
  //   ) {
  //     const popupContent = `
  //       <div style="text-align: center;" class="custom-popup">
  //         <div>
  //           <svg width="19" height="11" viewBox="0 0 19 11" fill="none" xmlns="http://www.w3.org/2000/svg">
  //             <mask id="mask0_53_371" style="mask-type:luminance" maskUnits="userSpaceOnUse" x="0" y="0" width="19" height="11">
  //             <path d="M18.1471 0.799805H0.914062V10.0148H18.1471V0.799805Z" fill="white"/>
  //             </mask>
  //             <g mask="url(#mask0_53_371)">
  //             <path d="M0.935051 9.95475C0.839547 8.45153 1.14006 6.94956 1.80659 5.59881C2.47313 4.24807 3.48235 3.09581 4.73348 2.25709C5.98462 1.41838 7.43389 0.922562 8.93659 0.819148C10.4393 0.715733 11.9428 1.00834 13.2971 1.66775L13.2291 1.71875C12.4351 2.29609 11.6404 2.87275 10.8451 3.44875C10.8334 3.45608 10.8204 3.46092 10.8068 3.46299C10.7932 3.46505 10.7793 3.46429 10.7661 3.46075C10.1766 3.26585 9.56528 3.1446 8.94605 3.09975C7.56019 2.99717 6.17314 3.28252 4.94032 3.92384C3.70749 4.56515 2.67761 5.53709 1.96605 6.73075C1.57614 7.38011 1.28876 8.08575 1.11405 8.82275C1.03179 9.18044 0.973655 9.54326 0.940051 9.90875V9.95475" fill="white"/>
  //             <path d="M13.2561 4.86593L14.8671 2.64893C14.9671 2.72793 15.0571 2.80393 15.1491 2.88193C16.7661 4.26926 17.8106 6.20745 18.0801 8.32093C18.1289 8.69321 18.1513 9.06848 18.1471 9.44393C18.1431 9.58809 18.0857 9.72564 17.9862 9.82999C17.8866 9.93435 17.7519 9.9981 17.6081 10.0089C17.4081 10.0189 17.2171 10.0089 17.0211 10.0089C16.8251 10.0089 16.6101 10.0089 16.4041 10.0089C16.2545 10.0148 16.1083 9.96349 15.9952 9.86541C15.882 9.76733 15.8105 9.62985 15.7951 9.48093C15.5398 7.70686 14.6545 6.08394 13.3011 4.90893L13.2651 4.87693C13.2615 4.87162 13.2585 4.86592 13.2561 4.85993" fill="white"/>
  //             <path d="M14.6889 1.61061L14.4239 1.97661C13.9126 2.67661 13.4009 3.37661 12.8889 4.07661L11.1109 6.52061L9.76094 8.38661C9.54194 8.68661 9.32594 8.99361 9.10094 9.28661C8.98818 9.42274 8.84405 9.52946 8.68094 9.59761C8.36553 9.75101 8.00617 9.78839 7.66594 9.70319C7.32572 9.61799 7.02638 9.41566 6.8205 9.13171C6.61461 8.84777 6.51533 8.50037 6.5401 8.15052C6.56487 7.80066 6.7121 7.47071 6.95594 7.21861C7.07324 7.11626 7.19791 7.02267 7.32894 6.93861L9.42894 5.41461L11.6079 3.83061L14.6189 1.64161L14.6809 1.59961L14.6879 1.60661" fill="white"/>
  //             </g>
  //           </svg>
  //           ${
  //             alertItem.points ? Math.ceil(alertItem.points.speed) : "0"
  //           } m/s</div>
  //                     <div><svg width="8" height="15" viewBox="0 0 8 15" fill="none" xmlns="http://www.w3.org/2000/svg">
  //           <mask id="mask0_53_379" style="mask-type:luminance" maskUnits="userSpaceOnUse" x="0" y="0" width="8" height="15">
  //           <path d="M7.63407 0.0600586H0.195068V14.9411H7.63407V0.0600586Z" fill="white"/>
  //           </mask>
  //           <g mask="url(#mask0_53_379)">
  //           <path d="M7.09498 10.7593C6.25498 10.7653 5.41498 10.7593 4.57498 10.7593H4.38898V4.1783H4.58098C5.42098 4.1783 6.26098 4.1783 7.10098 4.1783C7.20388 4.1882 7.30727 4.16504 7.39611 4.11217C7.48494 4.0593 7.55461 3.97947 7.59498 3.8843C7.63629 3.79 7.64535 3.6847 7.62077 3.58473C7.59618 3.48476 7.53931 3.39568 7.45898 3.3313C6.42398 2.3013 5.38898 1.2733 4.36098 0.238296C4.31216 0.183422 4.25248 0.139291 4.18571 0.108703C4.11894 0.0781153 4.04655 0.0617377 3.97311 0.0606094C3.89968 0.0594811 3.82681 0.0736268 3.75914 0.102149C3.69146 0.130671 3.63045 0.172948 3.57998 0.226296C2.51998 1.25963 1.45531 2.28863 0.385976 3.3133C0.302442 3.37752 0.242253 3.46734 0.214621 3.56902C0.186989 3.6707 0.193436 3.77863 0.232976 3.8763C0.274167 3.97641 0.34729 4.06011 0.440972 4.11436C0.534653 4.16862 0.643639 4.19039 0.750975 4.1763C1.57998 4.1763 2.40898 4.1763 3.23698 4.1763H3.41098V10.7593H3.22098C2.39198 10.7593 1.56298 10.7593 0.733975 10.7593C0.646719 10.7548 0.559428 10.7674 0.476976 10.7963C0.402918 10.8277 0.338173 10.8777 0.28894 10.9413C0.239707 11.0049 0.207634 11.0802 0.195792 11.1597C0.183951 11.2393 0.192736 11.3206 0.221309 11.3958C0.249881 11.471 0.297283 11.5377 0.358976 11.5893C0.936309 12.1693 1.51498 12.748 2.09498 13.3253C2.58498 13.8123 3.07298 14.2993 3.56898 14.7803C3.61271 14.8325 3.66768 14.8741 3.72978 14.9021C3.79187 14.9301 3.85949 14.9437 3.92757 14.9418C3.99565 14.94 4.06243 14.9228 4.12294 14.8916C4.18344 14.8603 4.2361 14.8158 4.27698 14.7613C5.33231 13.7146 6.38698 12.6673 7.44098 11.6193C7.52387 11.5537 7.58342 11.4631 7.6108 11.3609C7.63818 11.2588 7.63193 11.1506 7.59298 11.0523C7.55543 10.9534 7.48568 10.87 7.39498 10.8156C7.30428 10.7612 7.1979 10.7389 7.09298 10.7523" fill="white"/>
  //           </g>
  //           </svg>
  //           ${
  //             alertItem.points ? Math.ceil(alertItem.points.altitude) : "0"
  //           } m</div>
  //       </div>`;
  //     setSelectedAlert(alert);
  //     markerRefs.current[index].setPopupContent(popupContent).openPopup();
  //   }
  // };

  const memoizedSetLayers = useMemo(() => (e) => setLayers(e), [setLayers]);

  const memoizedSetAlarmFilter = useMemo(
    () => (e) => setAlarmFilter(e),
    [setAlarmFilter]
  );

  const memoizedFooterProps = useMemo(
    () => ({
      layers,
      onSetLayers: memoizedSetLayers,
      alarmFilter,
      onSetAlarmFilter: memoizedSetAlarmFilter,
    }),
    [layers, memoizedSetLayers, alarmFilter, memoizedSetAlarmFilter]
  );

  return (
    <>
      <Header alert={points.some((item) => item.alarmType === 4)} />
      {contextHolder}
      <div
        style={{
          height: "93vh",
          display: "flex",
          flexDirection: "column",
          overflow: "hidden",
        }}
      >
        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            flexGrow: 1,
            overflow: "hidden",
          }}
        >
          <SidePanel
            sensors={sensorList}
            alerts={alerts}
            inhibitors={inhibitorsList}
            onSelectSensor={(item) => onSelectSensor(item)}
            onSelectInhib={(item) => onSelectInhibitor(item)}
            onSelectAlert={(item, index) => {
              onSelectAlert(item, index);
            }}
            onHandleAlertTracking={(id) => {
              handleAlertTracking(id);
            }}
            selectedSensor={selectedSensor}
            selectedInhibitor={selectedInhibitor}
            selectedAlert={
              selectedAlert != null
                ? alerts.find(
                    (item) => item.alertId === selectedAlert.alertId
                  ) != undefined
                  ? alerts.find(
                      (item) => item.alertId === selectedAlert.alertId
                    )
                  : null
                : null
            }
            zones={zones}
            onSendInhibitorMessage={(msg) => sendInhibitorMessage(msg)}
            role={group != undefined && group != null ? group.roles[0] : ""}
          />
          <div style={{ flex: 3, backgroundColor: "#e9ecef", height: "100%" }}>
            {zones && points && (
              <MapContainer
                minZoom={2}
                center={
                  group != null && group.map_center
                    ? group.map_center
                    : [defaultCenter.lat, defaultCenter.lng]
                }
                zoom={group.zoom}
                style={{ height: "100%", width: "100%" }}
              >
                <LayersControl position="topright">
                  <BaseLayer name="Politico">
                    <TileLayer
                      url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
                      attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
                    />
                  </BaseLayer>
                  <BaseLayer checked name="Satelite">
                    <TileLayer
                      url="https://{s}.google.com/vt/lyrs=s&x={x}&y={y}&z={z}&key=AIzaSyCtVcbzEZJCQkDml1iKhhhqvUEpdGLAiSU"
                      subdomains={["mt0", "mt1", "mt2", "mt3"]}
                      attribution="&copy; Google"
                    />
                  </BaseLayer>
                </LayersControl>
                {/* {selectedInhibitor && (
                  <ToolTip
                    position={
                      selectedInhibitor != null
                        ? [selectedInhibitor.lat, selectedInhibitor.lng]
                        : null
                    }
                    speed={0}
                    altitude={0}
                  />
                )} */}
                {selectedAlert && (
                  <ToolTip
                    position={
                      selectedAlert != null &&
                      selectedAlert.lat != undefined &&
                      selectedAlert.lat != null &&
                      selectedAlert.lng != undefined &&
                      selectedAlert.lng != null
                        ? [selectedAlert.lat, selectedAlert.lng]
                        : null
                    }
                    speed={
                      selectedAlert != null && selectedAlert.speed
                        ? Math.ceil(selectedAlert.speed)
                        : 0
                    }
                    altitude={
                      selectedAlert != null && selectedAlert.altitude
                        ? Math.ceil(selectedAlert.altitude)
                        : 0
                    }
                  />
                )}
                {/* SENSORES EN EL MAPA */}
                {layers.sensors &&
                  sensorList.map((element) => {
                    const { icon, layer } = sensorIcon(element);
                    return (
                      <>
                        <Marker
                          key={element.id}
                          position={[element.lat, element.lng]}
                          icon={new L.Icon(icon)}
                          zIndexOffset={layer} // Higher zIndex for the active marker
                          eventHandlers={{
                            click: () => {
                              onSelectSensor(element);
                            },
                          }}
                        />
                        {element.radios.length > 0 &&
                          element.radiosFailCount < 4 && (
                            <Zone
                              key={element.radios[0].id} // Make sure to add a key if this is a list
                              pathOptions={{
                                color: element.radios[0].strokeColor,
                                fillColor: element.radios[0].fillColor,
                              }}
                              positions={element.radios[0].path}
                            />
                          )}
                      </>
                    );
                  })}
                {/* INHIBIDORES */}
                {inhibitorsList.map((element) => {
                  console.log("sectors: ", element.activeSectors);
                  const icon = inhibitorIcon(element);
                  return (
                    <React.Fragment key={element.id}>
                      <Marker
                        position={[element.lat, element.lng]}
                        icon={new L.Icon(icon.icon)}
                        zIndexOffset={icon.layer}
                        eventHandlers={{
                          click: () => {
                            onSelectInhibitor(element);
                          },
                        }}
                      />
                      <InhibitorRadio
                        key={element.id}
                        activeSectors={element.activeSectors}
                        sectors={element.sectors}
                        center={[element.lat, element.lng]}
                        selected={
                          selectedInhibitor !== null &&
                          selectedInhibitor.id === element.id
                        }
                        role={
                          group != undefined && group != null
                            ? group.roles[0]
                            : ""
                        }
                      />
                    </React.Fragment>
                  );
                })}

                {/* PUNTOS EN EL MAPA (HOME, DRONE Y PILOTO) */}
                {points.map((element, index) => {
                  const icon = droneIcon(element);
                  const alertItem = alerts.find(
                    (item) => item.alertId === element.alertId
                  );
                  return (
                    <Marker
                      key={element.id}
                      position={[element.lat, element.lng]}
                      icon={new L.Icon(icon.icon)}
                      zIndexOffset={icon.layer}
                      ref={(el) => (markerRefs.current[index] = el)}
                      eventHandlers={{
                        click: () => {
                          onSelectAlert(element, index);
                        },
                        // move: () => {
                        //   selectedAlert &&
                        //     element.id === selectedAlert.id &&
                        //     updatePopupContent(element, index);
                        // },
                      }}
                    ></Marker>
                  );
                })}

                {/* ZONAS EN EL MAPA */}
                {layers.zones &&
                  zones.map((element) => {
                    return (
                      <Zone
                        key={element.id} // Make sure to add a key if this is a list
                        pathOptions={{
                          color: element.strokeColor,
                          fillColor: element.fillColor,
                        }}
                        positions={element.zoneCordinates}
                      />
                    );
                  })}
                {/* HAZ DEL SENSOR */}
                {/* {memoRadios.map((element) => (
                <Zone
                  key={element.id} // Make sure to add a key if this is a list
                  pathOptions={{
                    color: element.strokeColor,
                    fillColor: element.fillColor,
                  }}
                  positions={element.path}
                />
              ))} */}
                <MapClickHandler />
                {center && <CenterMapOnMarker position={center} />}
              </MapContainer>
            )}
          </div>
        </div>
        <Footer {...memoizedFooterProps} />
      </div>
    </>
  );
}
