import api from "./api";

const HistoricServices = {
  getHistoricList: async (group_id) => {
    const response = await api({
      baseURL: `/services/historic/list/?group_id=${group_id}`,
      method: "GET",
    });
    return response;
  },
  getHistoricAlarm: async (alarm_id) => {
    const response = await api({
      baseURL: `/services/historic/?alarm_id=${alarm_id}`,
      method: "GET",
    });
    return response;
  },
};

export default HistoricServices;
