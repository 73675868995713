import React, { useEffect } from "react";
import HistoricServices from "../../services/HistoricServices";
import { useAuth } from "../../providers/AuthContex";
import Header from "../../components/Panels/Header";

export default function HistoricListScreen() {
  const { group } = useAuth();
  useEffect(() => {
    getHistoricList();
  }, []);

  const getHistoricList = async () => {
    try {
      const response = await HistoricServices.getHistoricList(group.group_id);
      console.log(response);
    } catch (error) {
      console.log("getHistoricList error: ", error);
    }
  };

  return (
    <>
      <Header />
    </>
  );
}
