import React, { useEffect } from "react";
import { useMap } from "react-leaflet";
import L from "leaflet";
import "../../components/Panels/css/ToolTip.css";

const ToolTip = ({ position, speed, altitude }) => {
  const map = useMap();

  useEffect(() => {
    let overlay;
    // if (position != undefined && position != null) {
    //   overlay = L.divOverlay = L.control({ position: "topright" });

    //   overlay.onAdd = function () {
    //     const div = L.DomUtil.create("div", "custom-overlay");
    //     div.innerHTML = `
    //     <div style="padding: 10px; background: white; border: 1px solid black; border-radius: 5px;">
    //       <h4>Custom Overlay</h4>
    //       <p>This is a custom overlay on the map!</p>
    //     </div>
    //   `;
    //     return div;
    //   };

    const divIcon = L.divIcon({
      className: "custom-overlay",
      html: `
        <div class="leaflet-popup-content-wrapper" style="width: 150px; display: flex; flex-direction: column; align-items: center; justify-content: center;">
        <div class="custom-popup">
            <div>
                <svg width="19" height="11" viewBox="0 0 19 11" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <mask id="mask0_53_371" style="mask-type:luminance" maskUnits="userSpaceOnUse" x="0" y="0" width="19" height="11">
                    <path d="M18.1471 0.799805H0.914062V10.0148H18.1471V0.799805Z" fill="white"/>
                    </mask>
                    <g mask="url(#mask0_53_371)">
                    <path d="M0.935051 9.95475C0.839547 8.45153 1.14006 6.94956 1.80659 5.59881C2.47313 4.24807 3.48235 3.09581 4.73348 2.25709C5.98462 1.41838 7.43389 0.922562 8.93659 0.819148C10.4393 0.715733 11.9428 1.00834 13.2971 1.66775L13.2291 1.71875C12.4351 2.29609 11.6404 2.87275 10.8451 3.44875C10.8334 3.45608 10.8204 3.46092 10.8068 3.46299C10.7932 3.46505 10.7793 3.46429 10.7661 3.46075C10.1766 3.26585 9.56528 3.1446 8.94605 3.09975C7.56019 2.99717 6.17314 3.28252 4.94032 3.92384C3.70749 4.56515 2.67761 5.53709 1.96605 6.73075C1.57614 7.38011 1.28876 8.08575 1.11405 8.82275C1.03179 9.18044 0.973655 9.54326 0.940051 9.90875V9.95475" fill="white"/>
                    <path d="M13.2561 4.86593L14.8671 2.64893C14.9671 2.72793 15.0571 2.80393 15.1491 2.88193C16.7661 4.26926 17.8106 6.20745 18.0801 8.32093C18.1289 8.69321 18.1513 9.06848 18.1471 9.44393C18.1431 9.58809 18.0857 9.72564 17.9862 9.82999C17.8866 9.93435 17.7519 9.9981 17.6081 10.0089C17.4081 10.0189 17.2171 10.0089 17.0211 10.0089C16.8251 10.0089 16.6101 10.0089 16.4041 10.0089C16.2545 10.0148 16.1083 9.96349 15.9952 9.86541C15.882 9.76733 15.8105 9.62985 15.7951 9.48093C15.5398 7.70686 14.6545 6.08394 13.3011 4.90893L13.2651 4.87693C13.2615 4.87162 13.2585 4.86592 13.2561 4.85993" fill="white"/>
                    <path d="M14.6889 1.61061L14.4239 1.97661C13.9126 2.67661 13.4009 3.37661 12.8889 4.07661L11.1109 6.52061L9.76094 8.38661C9.54194 8.68661 9.32594 8.99361 9.10094 9.28661C8.98818 9.42274 8.84405 9.52946 8.68094 9.59761C8.36553 9.75101 8.00617 9.78839 7.66594 9.70319C7.32572 9.61799 7.02638 9.41566 6.8205 9.13171C6.61461 8.84777 6.51533 8.50037 6.5401 8.15052C6.56487 7.80066 6.7121 7.47071 6.95594 7.21861C7.07324 7.11626 7.19791 7.02267 7.32894 6.93861L9.42894 5.41461L11.6079 3.83061L14.6189 1.64161L14.6809 1.59961L14.6879 1.60661" fill="white"/>
                    </g>
                </svg>
                ${speed} m/s
            </div>
            <div>
                <svg width="8" height="15" viewBox="0 0 8 15" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <mask id="mask0_53_379" style="mask-type:luminance" maskUnits="userSpaceOnUse" x="0" y="0" width="8" height="15">
                    <path d="M7.63407 0.0600586H0.195068V14.9411H7.63407V0.0600586Z" fill="white"/>
                    </mask>
                    <g mask="url(#mask0_53_379)">
                    <path d="M7.09498 10.7593C6.25498 10.7653 5.41498 10.7593 4.57498 10.7593H4.38898V4.1783H4.58098C5.42098 4.1783 6.26098 4.1783 7.10098 4.1783C7.20388 4.1882 7.30727 4.16504 7.39611 4.11217C7.48494 4.0593 7.55461 3.97947 7.59498 3.8843C7.63629 3.79 7.64535 3.6847 7.62077 3.58473C7.59618 3.48476 7.53931 3.39568 7.45898 3.3313C6.42398 2.3013 5.38898 1.2733 4.36098 0.238296C4.31216 0.183422 4.25248 0.139291 4.18571 0.108703C4.11894 0.0781153 4.04655 0.0617377 3.97311 0.0606094C3.89968 0.0594811 3.82681 0.0736268 3.75914 0.102149C3.69146 0.130671 3.63045 0.172948 3.57998 0.226296C2.51998 1.25963 1.45531 2.28863 0.385976 3.3133C0.302442 3.37752 0.242253 3.46734 0.214621 3.56902C0.186989 3.6707 0.193436 3.77863 0.232976 3.8763C0.274167 3.97641 0.34729 4.06011 0.440972 4.11436C0.534653 4.16862 0.643639 4.19039 0.750975 4.1763C1.57998 4.1763 2.40898 4.1763 3.23698 4.1763H3.41098V10.7593H3.22098C2.39198 10.7593 1.56298 10.7593 0.733975 10.7593C0.646719 10.7548 0.559428 10.7674 0.476976 10.7963C0.402918 10.8277 0.338173 10.8777 0.28894 10.9413C0.239707 11.0049 0.207634 11.0802 0.195792 11.1597C0.183951 11.2393 0.192736 11.3206 0.221309 11.3958C0.249881 11.471 0.297283 11.5377 0.358976 11.5893C0.936309 12.1693 1.51498 12.748 2.09498 13.3253C2.58498 13.8123 3.07298 14.2993 3.56898 14.7803C3.61271 14.8325 3.66768 14.8741 3.72978 14.9021C3.79187 14.9301 3.85949 14.9437 3.92757 14.9418C3.99565 14.94 4.06243 14.9228 4.12294 14.8916C4.18344 14.8603 4.2361 14.8158 4.27698 14.7613C5.33231 13.7146 6.38698 12.6673 7.44098 11.6193C7.52387 11.5537 7.58342 11.4631 7.6108 11.3609C7.63818 11.2588 7.63193 11.1506 7.59298 11.0523C7.55543 10.9534 7.48568 10.87 7.39498 10.8156C7.30428 10.7612 7.1979 10.7389 7.09298 10.7523" fill="white"/>
                    </g>
                </svg>
                ${altitude} m
            </div>
        </div>
        </div>
      `,
      iconSize: [150, 50],
      iconAnchor: [75, 75],
      popupAnchor: [0, 0],
    });

    overlay = L.marker(position, { icon: divIcon }).addTo(map);

    overlay.addTo(map);
    // }

    return () => {
      map.removeLayer(overlay);
    };
  }, [map, position, speed, altitude]);

  return null;
};

export default ToolTip;
