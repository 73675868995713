import React, { useEffect, useState } from "react";
import HistoricServices from "../../services/HistoricServices";
import { LayersControl, MapContainer, TileLayer } from "react-leaflet";
import { useAuth } from "../../providers/AuthContex";
import Header from "../../components/Panels/Header";

export default function HistoricAlarmScreen() {
  const [alarm, setAlarm] = useState([]);
  const [sensors, setSensors] = useState([]);
  const [drone, setDrone] = useState(null);
  const [home, setHome] = useState(null);
  const [pilot, setPilot] = useState(null);
  const { group } = useAuth();
  const { BaseLayer } = LayersControl;

  useEffect(() => {
    getHistoricAlarm();
  }, []);

  const sleep = (ms) => new Promise((resolve) => setTimeout(resolve, ms));

  const runAlarm = async () => {
    for (let index = 0; index < alarm.length; index++) {
      if (alarm[index + 1] != undefined) {
        const element = alarm[index];

        const point = element.alert.data.point;

        if (point != undefined && point != null) {
          console.log(point.type);
        }

        const nextTimeStamp = alarm[index + 1].timestamp;
        const stopTime = Math.abs(
          new Date(nextTimeStamp) - new Date(element.timestamp)
        );

        await sleep(stopTime);
      }
    }
  };

  const getHistoricAlarm = async () => {
    try {
      const response = await HistoricServices.getHistoricAlarm(
        "677e774f3cb9883585734481"
      );
      console.log(response.data.data);
      setAlarm(response.data.data);
    } catch (error) {
      console.log("getHistoricList error: ", error);
    }
  };

  const defaultCenter = {
    lat: 41.37318481141028,
    lng: 2.1531474024100135,
    type: "home",
  };

  return (
    <div>
      <Header />
      <div onClick={() => runAlarm()}>run</div>
      <div
        style={{
          height: "93vh",
          display: "flex",
          flexDirection: "column",
          overflow: "hidden",
        }}
      >
        <div style={{ flex: 3, backgroundColor: "red", height: "100%" }}>
          <MapContainer
            minZoom={10}
            center={
              alarm.length > 0
                ? [alarm[0].alert.data.point.lat, alarm[0].alert.data.point.lng]
                : [defaultCenter.lat, defaultCenter.lng]
            }
            zoom={group.zoom}
            style={{ height: "100%", width: "100%" }}
          >
            <LayersControl position="topright">
              <BaseLayer name="Politico">
                <TileLayer
                  url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
                  attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
                />
              </BaseLayer>
              <BaseLayer checked name="Satelite">
                <TileLayer
                  url="https://{s}.google.com/vt/lyrs=s&x={x}&y={y}&z={z}&key=AIzaSyCtVcbzEZJCQkDml1iKhhhqvUEpdGLAiSU"
                  subdomains={["mt0", "mt1", "mt2", "mt3"]}
                  attribution="&copy; Google"
                />
              </BaseLayer>
            </LayersControl>
          </MapContainer>
        </div>
      </div>
    </div>
  );
}
